import { useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

import { AnalyticsContext } from './analytics-provider';

// Create an analytics hook that we can use with other components.
const useAnalytics = () => {
    const result = useContext(AnalyticsContext);

    if (!result) {
        throw new Error('Context used outside of its Provider!');
    }

    return result;
};

const useTrack = () => {
    const context = useContext(AnalyticsContext);

    const trackCb = useCallback(
        async (eventName: string, params?: any) => {
            if (!context?.track) {
                return false;
            }
            const actionName = params?.props?.action ?? '';
            await context.track(eventName + actionName, {
                source: params?.source === '/' ? 'frontpage' : params?.source,
                ...params,
            });
            return true;
        },
        [context],
    );

    return trackCb;
};

const usePage = () => {
    return useContext(AnalyticsContext).page;
};

const useIdentify = () => {
    return useContext(AnalyticsContext).identify;
};

const useReset = () => {
    return useContext(AnalyticsContext).reset;
};

const usePageView = ({ meta }: { meta: any }) => {
    const page = usePage();
    const router = useRouter();

    const onPageView = useCallback(
        (url: string, metadata?: any) => {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.log(`onPageView: ${url}`, metadata);
            }

            if (metadata?.authors || metadata?.tags || metadata?.categories) {
                page({
                    title: metadata?.title ?? undefined,
                    author: (metadata?.authors || []).join(','),
                    authors: metadata?.authors || [],
                    tags: metadata?.tags || [],
                    categories: metadata?.categories || [],
                    article_modified_time: metadata?.article_modified_time ?? undefined,
                    article_published_time: metadata?.article_published_time ?? undefined,
                });

                return;
            }
            page({ url });
        },
        [router.asPath],
    );

    useEffect(() => {
        onPageView(router.asPath, meta);
    }, [router.asPath]);
};

export { useAnalytics, useIdentify, usePage, usePageView, useReset, useTrack };
