import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { fetcher } from '@blockworks/platform/api';
import { useLocalStorage } from '@blockworks/platform/hooks';

interface AlgoliaContextValue {
    algoliaKey: string | null;
    loading: boolean;
    error: boolean;
}

const AlgoliaContext = createContext<AlgoliaContextValue | undefined>(undefined);

interface AlgoliaProviderProps {
    children: React.ReactNode;
}

const LOCAL_STORAGE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours

export const AlgoliaProvider = ({ children }: AlgoliaProviderProps) => {
    const { storedValue: algoliaKey, setValue: setAlgoliaKey } = useLocalStorage<string | null>(
        'algoliaKey',
        null,
        LOCAL_STORAGE_EXPIRATION,
    );

    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);

    useEffect(() => {
        let mounted = true;
        const retryAttempts = 3;
        const retryDelay = 1000;

        const fetchAlgoliaKey = async () => {
            try {
                setLoading(true);
                let attempts = 0;
                let error;

                while (attempts < retryAttempts) {
                    try {
                        const securedKey = await fetcher<false, void, { apiKey: string }>({
                            url: '/api/algolia/secured-key',
                            method: fetcher.Method.Post,
                        });

                        if (mounted) {
                            setAlgoliaKey(securedKey.apiKey);
                            setError(false);
                        }
                        return;
                    } catch (e) {
                        error = e;
                        attempts++;
                        if (attempts < retryAttempts) {
                            await new Promise(resolve => setTimeout(resolve, retryDelay));
                        }
                    }
                }
                throw error;
            } catch (err: unknown) {
                if (mounted) {
                    console.error(err);
                    setError(true);
                }
            } finally {
                if (mounted) {
                    setLoading(false);
                }
            }
        };

        if (!algoliaKey) {
            fetchAlgoliaKey();
        }

        return () => {
            mounted = false;
        };
    }, [algoliaKey, setAlgoliaKey]);

    const value: AlgoliaContextValue = useMemo(
        () => ({
            algoliaKey,
            loading,
            error,
        }),
        [algoliaKey, error, loading],
    );

    return <AlgoliaContext.Provider value={value}>{children}</AlgoliaContext.Provider>;
};

export const useAlgolia = (): AlgoliaContextValue => {
    const context = useContext(AlgoliaContext);
    if (!context) {
        throw new Error('useAlgolia must be used within an AlgoliaProvider');
    }
    return context;
};
