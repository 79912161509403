import { tVariants } from '../../../utils/t-variants';

const tvMiscellaneous = tVariants(
    {
        variants: {
            hoverCursor: {
                text: 'hover:cursor-text',
                draggable: 'hover:cursor-grab',
            },
        },
    },
    { responsiveVariants: true },
);

const miscellaneous = tvMiscellaneous.variants;

export { miscellaneous };
