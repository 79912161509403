import { useEffect } from 'react';

import { useAds } from '@/context/ads';

import { ContainerDef } from '../ad-container-ids';

export interface AdSlotAttr {
    id: string;
    'data-ad-slot': string;
    'data-ad-client': string | undefined;
    style: Record<string, number | string>;
}

const useAdSlot = (adUnit: ContainerDef): AdSlotAttr => {
    const { status, defineAdSlot, destroySlot, slotMap } = useAds();

    useEffect(() => {
        if (status === 'ready' && !slotMap.get(adUnit.id)) {
            defineAdSlot(adUnit);
        }
        // Remember pushed ad slot to not define/push it twice
    }, [defineAdSlot, destroySlot, slotMap, status, adUnit]);

    useEffect(() => {
        return () => destroySlot(adUnit);
    }, [destroySlot, adUnit]);

    const {
        id,
        slot,
        sizes: [width, height],
    } = adUnit;

    const attrs = {
        id,
        'data-ad-slot': slot,
        'data-ad-client': process.env.GOOGLE_ADS_CLIENT_ID,
        style: {},
    };

    if (!Array.isArray(width) && !Array.isArray(height)) {
        attrs.style = { width, height };
    }

    return attrs;
};

export default useAdSlot;
