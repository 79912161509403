import { tVariants } from '../../../utils/t-variants';

const tvLayout = tVariants(
    {
        variants: {
            transform: {
                translateXCenter: 'left-1/2 transform -translate-x-1/2',
                translateYCenter: 'top-1/2 transform -translate-y-1/2',
                translateCenter: 'top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2',
            },
            alignItems: {
                start: 'items-start',
                end: 'items-end',
                center: 'items-center',
                stretch: 'items-stretch',
            },
            justifyContent: {
                start: 'justify-start',
                end: 'justify-end',
                center: 'justify-center',
                stretch: 'justify-stretch',
                between: 'justify-between',
                evenly: 'justify-evenly',
                around: 'justify-around',
            },
            flexDirection: {
                column: 'flex-col',
                row: 'flex-row',
                'row-reverse': 'flex-row-reverse',
                'col-reverse': 'flex-col-reverse',
            },
            flex: {
                1: 'flex-1',
                auto: 'flex-auto',
                initial: 'flex-initial',
                none: 'flex-none',
            },
            flexWrap: {
                wrap: 'flex-wrap',
                reverse: 'flex-wrap-reserve',
                nowrap: 'flex-nowrap',
            },
            flexGrow: {
                0: 'grow-0',
                1: 'grow',
            },
            flexShrink: {
                0: 'shrink-0',
                1: 'shrink',
            },
            justifySelf: {
                end: 'justify-self-end',
                center: 'justify-self-center',
                start: 'justify-self-start',
            },
            alignSelf: {
                end: 'align-self-end',
                center: 'align-self-center',
                start: 'align-self-start',
                stretch: 'self-stretch',
            },
            colStart: {
                1: 'col-start-1',
                2: 'col-start-2',
                3: 'col-start-3',
                4: 'col-start-4',
                5: 'col-start-5',
                6: 'col-start-6',
                7: 'col-start-7',
                8: 'col-start-8',
                9: 'col-start-9',
                10: 'col-start-10',
                11: 'col-start-11',
            },
            colSpan: {
                1: 'col-span-1',
                2: 'col-span-2',
                3: 'col-span-3',
                4: 'col-span-4',
                5: 'col-span-5',
                6: 'col-span-6',
                7: 'col-span-7',
                8: 'col-span-8',
                9: 'col-span-9',
                10: 'col-span-10',
                11: 'col-span-11',
                12: 'col-span-12',
            },
            rowSpan: {
                1: 'row-span-1',
                2: 'row-span-2',
                3: 'row-span-3',
                4: 'row-span-4',
                5: 'row-span-5',
                6: 'row-span-6',
            },
            overflow: {
                auto: 'overflow-auto',
                hidden: 'overflow-hidden',
                clip: 'overflow-clip',
                visible: 'overflow-visible',
                scroll: 'overflow-scroll',
            },
            overflowX: {
                auto: 'overflow-x-auto',
                hidden: 'overflow-x-hidden',
                clip: 'overflow-x-clip',
                visible: 'overflow-x-visible',
                scroll: 'overflow-x-scroll',
            },
            overflowY: {
                auto: 'overflow-y-auto',
                hidden: 'overflow-y-hidden',
                clip: 'overflow-y-clip',
                visible: 'overflow-y-visible',
                scroll: 'overflow-y-scroll',
            },
            display: {
                block: 'block',
                'inline-block': 'inline-block',
                flex: 'flex',
                'inline-flex': 'inline-flex',
                inline: 'inline',
                hidden: 'hidden',
                grid: 'grid',
            },
            pos: {
                static: 'static',
                absolute: 'absolute',
                relative: 'relative',
                fixed: 'fixed',
                sticky: 'sticky',
            },
            top: {
                0: 'top-0',
                2: 'top-2',
                4: 'top-4',
                6: 'top-6',
                8: 'top-8',
                10: 'top-10',
                12: 'top-12',
                16: 'top-16',
                20: 'top-20',
            },
            right: {
                0: 'right-0',
                2: 'right-2',
                4: 'right-4',
                6: 'right-6',
                8: 'right-8',
                10: 'right-10',
                12: 'right-12',
                16: 'right-16',
                20: 'right-20',
            },
            bottom: {
                0: 'bottom-0',
                2: 'bottom-2',
                4: 'bottom-4',
                6: 'bottom-6',
                8: 'bottom-8',
                10: 'bottom-10',
                12: 'bottom-12',
                16: 'bottom-16',
                20: 'bottom-20',
            },
            left: {
                0: 'left-0',
                2: 'left-2',
                4: 'left-4',
                6: 'left-6',
                8: 'left-8',
                10: 'left-10',
                12: 'left-12',
                16: 'left-16',
                20: 'left-20',
            },
        },
    },
    { responsiveVariants: true },
);

const layout = tvLayout.variants;

const layoutAtoms = {
    flex: {
        alignItems: layout.alignItems,
        justifyContent: layout.justifyContent,
        flexDirection: layout.flexDirection,
        flex: layout.flex,
        flexWrap: layout.flexWrap,
        flexGrow: layout.flexGrow,
        flexShrink: layout.flexShrink,
        justifySelf: layout.justifySelf,
        alignSelf: layout.alignSelf,
    },
    grid: {
        colStart: layout.colStart,
        colSpan: layout.colSpan,
        rowSpan: layout.rowSpan,
    },
    overflow: {
        overflow: layout.overflow,
        overflowX: layout.overflowX,
        overflowY: layout.overflowY,
    },
    position: {
        display: layout.display,
        pos: layout.pos,
        top: layout.top,
        right: layout.right,
        bottom: layout.bottom,
        left: layout.left,
    },
};

export { layout, layoutAtoms };
