import { AnyObj } from '../../../typescript';
import { HttpMethod } from '../http-method';
import { UserAgent } from '../user-agent';

import type { FetcherOptions, OriginalResponse } from './fetcher.model';
import { getFetchOptions, parseFetchResponse } from './fetcher.utils';

type FetcherReturn<ResBody, OriginalRes> = Promise<OriginalRes extends true ? OriginalResponse<ResBody> : ResBody>;

/**
 * Thin wrapper around `fetch` to make responses, mainly errors, easier to deal with.
 * @see FetcherError Convenient for asserting caught errors e.g.
 */
const fetcher = async <OriginalRes extends boolean, ReqBody extends AnyObj | void = AnyObj, ResBody = AnyObj>({
    url,
    method = HttpMethod.Get,
    body,
    sessionToken,
    headers,
    originalResponse,
    serverSideUserAgent,
    ...restOverrides
}: FetcherOptions<OriginalRes, ReqBody>): FetcherReturn<ResBody, OriginalRes> => {
    const response = await fetch(url, {
        ...getFetchOptions({
            method,
            body,
            sessionToken,
            headers,
            serverSideUserAgent,
        }),
        ...restOverrides,
    });
    if (originalResponse) return response as unknown as FetcherReturn<ResBody, OriginalRes>;
    return parseFetchResponse<ResBody>(response) as unknown as FetcherReturn<ResBody, OriginalRes>;
};

fetcher.Method = HttpMethod;
fetcher.UserAgent = UserAgent;

export { fetcher };
