import { tVariants } from '../../../utils/t-variants';

const tvColor = tVariants(
    {
        variants: {
            color: {
                base: 'text-content',
                contrast: 'text-content-contrast',
                muted: 'text-content-muted',
                highlight: 'text-content-highlight',
                deselect: 'text-content-deselected',
                interact: 'text-content-interact',
                primary: 'text-content-primary',
                interactive: 'text-content-interactive',
                warning: 'text-content-warning',
                success: 'text-content-success',
                danger: 'text-content-danger',
                info: 'text-content-info',
                transparent: 'text-transparent',
                current: 'text-current',
            },
            bgColor: {
                muted: 'bg-surface-muted',
                highlight: 'bg-surface-highlight',
                selected: 'bg-surface-selected',
                interact: 'bg-surface-interact',
                primary: 'bg-primary-surface',
                base: 'bg-surface',
                contrast: 'bg-surface-contrast',
                warning: 'bg-warning-surface',
                current: 'bg-current-bg',
                currentColor: '[--color-current-bg:currentColor] bg-current-bg',
                info: 'bg-info-surface',
                raised: 'bg-surface-raised',
                depth: 'bg-surface-depth',
            },
            opacity: {
                0: 'opacity-0',
                10: 'opacity-10',
                20: 'opacity-20',
                30: 'opacity-30',
                40: 'opacity-40',
                50: 'opacity-50',
                60: 'opacity-60',
                70: 'opacity-70',
                80: 'opacity-80',
                90: 'opacity-90',
                100: 'opacity-100',
            },
            fill: {
                base: 'fill-content',
                muted: 'fill-content-muted',
                highlight: 'fill-content-highlight',
                deselect: 'fill-content-deselected',
                interact: 'fill-content-interact',
                primary: 'fill-content-interactive',
                success: 'fill-content-success',
                warning: 'fill-content-warning',
                danger: 'fill-content-danger',
                transparent: 'fill-transparent',
            },
            stroke: {
                base: 'stroke-content',
                muted: 'stroke-content-muted',
                highlight: 'stroke-content-highlight',
                deselect: 'stroke-content-deselected',
                interact: 'stroke-content-interact',
                success: 'stroke-content-success',
                warning: 'stroke-content-warning',
                danger: 'stroke-content-danger',
                transparent: 'stroke-transparent',
            },
        },
    },
    { responsiveVariants: true },
);

const colorAtoms = {
    svg: {
        fill: tvColor.variants.fill,
        stroke: tvColor.variants.stroke,
    },
};

const color = tvColor.variants;
export { color, colorAtoms };
