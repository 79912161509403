import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { BeehiivPublicationIds } from '@blockworks/platform/api/beehiiv';
import { useIdentify, useTrack } from '@blockworks/platform/services/analytics';
import { Spinner } from '@blockworks/ui/spinner';
import { cn } from '@blockworks/ui/utils';

import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

type NewsletterSubscribeInputProps = {
    className?: string;
    inputPlaceholder: string;
    buttonText: string;
    source?: string;
    onButtonClick: typeof subscribeToNewsletter;
    size?: 'md' | 'lg';
};

const BOT_WAIT_TIME = 2000;

enum ErrorType {
    BotProtectionError = 'botProtectionError',
    GenericError = 'genericError',
}

interface ErrorState {
    type: ErrorType | null;
    message: string | null;
}

const NewsletterSubscribeInput = (props: NewsletterSubscribeInputProps) => {
    const track = useTrack();
    const identify = useIdentify();
    const [isLoading, setIsLoading] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const { inputPlaceholder, buttonText, onButtonClick, className, source } = props;
    const [errorState, setErrorState] = useState<ErrorState>({ type: null, message: null });
    const [inputText, setInputText] = useState<string>('');
    const [verification, setVerification] = useState<string>('');
    const [placeholderText, setPlaceholderText] = useState<string>(inputPlaceholder);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    // time tracking
    const [startTime, setStartTime] = useState<number>(0);

    useEffect(() => {
        if (errorState.type === 'botProtectionError' && errorState.message) {
            timeoutRef.current = setTimeout(() => {
                setErrorState({ type: null, message: null });
            }, BOT_WAIT_TIME);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [errorState]);

    return (
        <div className="flex flex-col w-full">
            <div className={cn('flex justify-start items-center self-stretch', className)}>
                <div className="self-stretch flex-grow bg-white">
                    <input
                        type="hidden"
                        name="verification"
                        value={verification}
                        onChange={ev => setVerification(ev.target.value)}
                    />
                    <input
                        className={cn(
                            'flex justify-start items-start w-full text-light-gray focus:text-black border focus:outline-none border-r-0',
                            !subscribed
                                ? 'border-gray-200 focus:border-primary'
                                : 'cursor-default border-green-400 focus:border-green-400',
                            props.size === 'lg' ? 'p-4 text-md' : 'p-2 text-xs',
                            errorState.message && 'border-red-400 focus:border-red-200',
                        )}
                        onFocus={() => setStartTime(Date.now())}
                        onChange={ev => setInputText(ev.target.value)}
                        value={inputText}
                        placeholder={placeholderText}
                    />
                </div>
                <button
                    className={cn(
                        'flex justify-start items-center gap-1 border border-transparent cursor-pointer border-l-0',
                        !subscribed
                            ? 'bg-primary hover:border-gray-800 hover:bg-gray-700'
                            : 'bg-green-400 cursor-default',
                        props.size === 'lg' ? 'px-8 py-4 text-md' : 'px-4 py-2 text-xs',
                        errorState.message && 'bg-red-400',
                    )}
                    disabled={subscribed}
                    onClick={() => {
                        identify(inputText, {
                            subscribedToNewsletter: true,
                        });

                        // Bot protection:
                        // Calculate time difference & only allow form submission if time diff is greater than 2 seconds
                        if (startTime > 0 && Date.now() - startTime < BOT_WAIT_TIME) {
                            setErrorState({
                                type: ErrorType.BotProtectionError,
                                message: 'Bot protection: Please wait a few seconds before submitting again.',
                            });

                            return;
                        }

                        // Honeypot - if the hidden field is filled, it's a bot
                        if (verification !== '') {
                            setErrorState({
                                type: ErrorType.GenericError,
                                message: 'Error.',
                            });
                            return;
                        }

                        setIsLoading(true);
                        onButtonClick(
                            inputText,
                            [
                                BeehiivPublicationIds.Daily,
                                BeehiivPublicationIds.Weekly,
                                BeehiivPublicationIds.Events,
                                BeehiivPublicationIds.Webinars,
                            ],
                            source,
                            window?.location?.pathname ?? '',
                        )
                            .then(result => {
                                if (result && result!.success) {
                                    setErrorState({ type: null, message: null });
                                    setInputText('');
                                    setPlaceholderText('Subscribed - Thank you!');
                                    setSubscribed(true);
                                    identify(inputText);
                                    track('Newsletter.Subscribe', {
                                        email: inputText,
                                        subscribed: true,
                                        source,
                                    });
                                } else {
                                    setErrorState({
                                        type: ErrorType.GenericError,
                                        message: 'There was an error subscribing to our newsletter. Please try again.',
                                    });
                                    track('Newsletter.Subscribe.Error', {
                                        email: inputText,
                                        subscribed: false,
                                        source,
                                    });
                                }
                                setIsLoading(false);
                            })
                            .catch(error => {
                                setErrorState({
                                    type: ErrorType.GenericError,
                                    message: `${error}`,
                                });
                                setIsLoading(false);
                            });
                    }}
                >
                    <span className="text-center uppercase text-white">
                        {!isLoading ? (
                            <>
                                {subscribed && (
                                    <CheckCircleIcon className={props.size === 'lg' ? 'w-6 h-6' : 'w-4 h-4'} />
                                )}
                                {!subscribed && <>{buttonText}</>}
                            </>
                        ) : (
                            <Spinner size={props.size === 'lg' ? 6 : 4} color="white" />
                        )}
                    </span>
                </button>
            </div>
            {errorState.message && (
                <p className="text-xs text-red-500 p-1.5 bg-red-50 border border-t-0 border-red-200">
                    {errorState.message}
                </p>
            )}
        </div>
    );
};

export default NewsletterSubscribeInput;
