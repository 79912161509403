import { tVariants } from '../../../utils/t-variants';

const tvBorder = tVariants(
    {
        variants: {
            border: {
                0: 'border-0',
                1: 'border',
                2: 'border-2',
                4: 'border-4',
                8: 'border-8',
                'divide-x': 'divide-x divide-surface-divider',
                'x-0': 'divide-x-0',
                'x-muted': 'divide-x divide-surface-divider',
                'y-0': 'divide-y-0',
                'y-muted': 'divide-y divide-surface-divider',
                'divide-y': 'divide-y divide-surface-divider',
            },
            borderLeft: {
                0: 'border-l-0',
                1: 'border-l',
                2: 'border-l-2',
                4: 'border-l-4',
                8: 'border-l-8',
            },
            borderRight: {
                0: 'border-r-0',
                1: 'border-r',
                2: 'border-r-2',
                4: 'border-r-4',
                8: 'border-r-8',
            },
            borderTop: {
                0: 'border-t-0',
                1: 'border-t',
                2: 'border-t-2',
                4: 'border-t-4',
                8: 'border-t-8',
            },
            borderBottom: {
                0: 'border-b-0',
                1: 'border-b',
                2: 'border-b-2',
                4: 'border-b-4',
                8: 'border-b-8',
            },
            borderColor: {
                divider: 'border-surface-divider',
                muted: 'border-surface-muted',
                highlight: 'border-surface-highlight',
                selected: 'border-surface-selected',
                deselect: 'border-surface-deselected',
                interact: 'border-surface-interact',
                base: 'border-surface',
            },
            borderRadius: {
                none: 'rounded-none',
                sm: 'rounded-sm',
                md: 'rounded-md',
                lg: 'rounded-lg',
                xl: 'rounded-xl',
                '2xl': 'rounded-2xl',
                full: 'rounded-full',
            },
            shadow: {
                sm: 'shadow-sm',
                md: 'shadow-md',
                lg: 'shadow-lg',
            },
        },
    },
    { responsiveVariants: true },
);

const border = tvBorder.variants;
export { border };
