import { border } from './config/border';
import { color, colorAtoms } from './config/color';
import { layout, layoutAtoms } from './config/layout';
import { miscellaneous } from './config/miscellaneous';
import { size } from './config/size';
import { space, spaceAtoms } from './config/space';
import { typography } from './config/typography';

const factoryStyleProps = {
    space,
    size,
    typography,
    color,
    border,
    layout,
    miscellaneous,
    /** smaller css property groupings */
    __atoms: {
        space: spaceAtoms,
        layout: layoutAtoms,
        color: colorAtoms,
    },
};

export { factoryStyleProps };
