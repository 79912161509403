import type { Config, DefaultClassGroupIds, DefaultThemeGroupIds } from 'tailwind-merge';
import { createTV } from 'tailwind-variants';

type ClassGroups = Partial<Config<DefaultClassGroupIds, DefaultThemeGroupIds>['classGroups']>;
/**
 * The`tailwind-variants` helper for the `ui` library, pre-configured for tailwind-merge.
 * */
const tVariants = createTV({
    twMerge: true,
    twMergeConfig: {
        extend: {
            /**
             * these class groups simply match our
             * custom values from the tailwind theme config
             * */
            classGroups: {
                leading: ['leading-ui'],
                animate: [
                    {
                        animate: [
                            'slide-in-right',
                            'slide-out-right',
                            'slide-in-left',
                            'slide-out-left',
                            'slide-in-bottom',
                            'slide-out-bottom',
                        ],
                    },
                ],
                'text-color': [
                    {
                        text: ['primary', 'content', 'content-selected', 'content-deselected'],
                    },
                ],
                'font-size': [
                    {
                        text: [
                            'inherit',
                            'xxs',
                            'display-xs',
                            'display-sm',
                            'display-md',
                            'display-lg',
                            'display-xl',
                            'display-2xl',
                            'label',
                        ],
                    },
                ],
            } satisfies ClassGroups,
        },
    },
});

export { tVariants };
