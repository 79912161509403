import { fetcher } from '@blockworks/platform/api';
import { BeehiivPublicationIds } from '@blockworks/platform/api/beehiiv';

export const allNewsletters: BeehiivPublicationIds[] = [
    BeehiivPublicationIds.Daily,
    BeehiivPublicationIds.Weekly,
    BeehiivPublicationIds.Events,
    BeehiivPublicationIds.Webinars,
    BeehiivPublicationIds.ResearchDaily,
    BeehiivPublicationIds.Empire,
    BeehiivPublicationIds.Lightspeed,
    BeehiivPublicationIds.ForwardGuidance,
];

/**
 * Subscribes email to newsletter
 *
 * @param email
 * @param subscribeTo
 * @param source
 * @param sourcePath
 * @param campaign
 * @param medium
 */
const subscribeToNewsletter = async (
    email: string,
    subscribeTo: BeehiivPublicationIds[] = allNewsletters,
    source: string = 'Website',
    sourcePath: string = '',
    campaign: string = '',
    medium: string = 'organic',
) => {
    if (!email) {
        return Promise.reject(new Error('Email address required'));
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email)) {
        return Promise.reject(new Error('Invalid email address'));
    }

    return fetcher<false, any, any>({
        url: '/api/newsletter/signup',
        body: {
            email,
            subscribeTo,
            source,
            sourcePath,
            campaign,
            medium,
        },
        method: fetcher.Method.Post,
    });
};

export default subscribeToNewsletter;
