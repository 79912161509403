import * as React from 'react';
import { ReactNode } from 'react';

import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { RenderMarkdown } from '@blockworks/ui/legacy/html';
import { Link } from '@blockworks/ui/legacy/link';

import AdContainer from '@/components/ads/ad-container';
import AdContainerIds from '@/components/ads/ad-container-ids';
import { AudioProvider } from '@/components/pages/podcast/layout/audio-provider';
import { AudioPlayer } from '@/components/player/audio-player';
import { NewsArchiveProvider } from '@/context/news-archive-ctx';
import { useOptions } from '@/context/options';
import Footer from '@/layout/containers/default-container/footer';
import type { MenuItem } from '@/layout/containers/default-container/main-menu/main-menu';
import MainMenu from '@/layout/containers/default-container/main-menu/main-menu';
import Ticker from '@/layout/containers/default-container/ticker/ticker';
import type { GlobalOptionProps } from '@/types/_app';
import FeatureFlags from '@/types/feature-flags';
import usePriceTicker from '@/utils/hooks/use-price-ticker';

const newsCategoryItems: MenuItem[] = [
    {
        name: 'Markets',
        url: 'markets',
        id: 201,
    },
    {
        name: 'Finance',
        url: 'finance',
        id: 142,
    },
    {
        name: 'Policy',
        url: 'policy',
        id: 235,
    },
    {
        name: 'Business',
        url: 'business',
        id: 4637,
    },
    {
        name: 'DeFi',
        url: 'defi',
        id: 535,
    },
    {
        name: 'Web3',
        url: 'web3',
        id: 201,
    },
    {
        name: 'People',
        url: 'people',
        id: 255,
    },
    {
        name: 'Opinion',
        url: 'opinion',
        id: 202,
    },
    {
        name: 'Education',
        url: 'education',
        id: 224,
    },
    {
        name: 'Sponsored',
        url: 'sponsored',
        id: 171,
    },
    {
        name: 'Analysis',
        url: 'analysis',
        id: 4778,
    },
    {
        name: 'Announcements',
        url: 'announcements',
        id: 91,
    },
];

const subMenu = [{ name: 'News Archive', url: '/news' }];

type DefaultContainerProps = {
    meta: ReactNode;
    options: GlobalOptionProps;
    featureFlags: FeatureFlags;
    children?: ReactNode;
};

/**
 * @param options
 */
const tickerSponsor = (options: GlobalOptionProps) => {
    if (options.tickerImageUrl) {
        return (
            <Link
                href={options.tickerUrl}
                prefetch={false}
                className="flex flex-row justify-start items-center relative gap-2"
                target="_blank"
                rel="nofollow"
            >
                <span className="flex text-xxs lowercase text-light-gray">sponsored by</span>
                <ImageWithFallback src={options.tickerImageUrl} alt={'Ticker Sponsor'} width={120} height={20} />
            </Link>
        );
    }

    return <AdContainer googleAd={AdContainerIds.Ticker120x20!} />;
};

const DefaultContainer = (props: DefaultContainerProps) => {
    const { meta, children } = props;
    const tickerItems = usePriceTicker();
    const options = useOptions();

    const footerMenuSections = [
        {
            name: 'News',
            url: '/news',
        },
        {
            name: 'Podcasts',
            url: '/podcasts',
        },
        {
            name: 'Newsletters',
            url: '/newsletter',
        },
        {
            name: 'Events',
            url: '/events',
        },
        {
            name: 'Roundtables',
            url: '/roundtables',
        },
        {
            name: 'Research',
            url: 'https://blockworksresearch.com',
        },
        {
            name: 'Sitemap',
            url: '/sitemap',
        },
    ];

    const footerMenuCompany = [
        {
            name: 'About',
            url: '/about',
        },
        {
            name: 'Advertise',
            url: '/advertise',
        },
        {
            name: 'Careers',
            url: '/careers',
        },
        {
            name: 'Trust & Ethics',
            url: '/trust-ethics',
        },
        {
            name: 'Privacy Policy',
            url: '/privacy-policy',
        },
        {
            name: 'Contact Us',
            url: '/contact-us',
        },
    ];

    /*
const navigation: INavigationItem[] = [
{name: 'Home', url: '/', icon: HomeIcon, current: router.pathname === '/'},
];
*/
    return (
        <div className="relative flex flex-col justify-start items-start 2xl:border-l 2xl:border-r border-gray-200 max-w-screen-2xl mx-auto">
            <>
                {meta}

                <header className="w-full border-b border-gray-200">
                    <div className="text-xs">
                        {options.config.alertBarContent ? (
                            <div className="top-bar bg-primary min-h-[2.5rem]">
                                <RenderMarkdown
                                    text={options.config.alertBarContent || '&nbsp;'}
                                    className="text-xxs md:text-xs xl:text-sm"
                                />
                            </div>
                        ) : (
                            <div className="top-bar bg-primary min-h-[1rem]" />
                        )}
                        <NewsArchiveProvider>
                            <MainMenu
                                newsCategoryItems={newsCategoryItems}
                                newsSubMenuItems={subMenu}
                                featureFlags={options.featureFlags}
                            />
                        </NewsArchiveProvider>
                        <div className="h-10 overflow-hidden">
                            <Ticker items={tickerItems} sponsor={tickerSponsor(options.config)} />
                        </div>
                    </div>
                </header>

                <AudioProvider>
                    {children}

                    <div className="fixed bottom-0 z-10 left-0 right-0">
                        <AudioPlayer />
                    </div>
                </AudioProvider>

                <Footer
                    address="133 W 19th St., New York, NY 10011"
                    footerMenuSections={footerMenuSections}
                    footerMenuCompany={footerMenuCompany}
                />
            </>
        </div>
    );
};

export default DefaultContainer;
