import { tVariants } from '../../../utils/t-variants';

const tvTypography = tVariants(
    {
        variants: {
            lineClamp: {
                1: 'line-clamp-1',
                2: 'line-clamp-2',
                3: 'line-clamp-3',
                4: 'line-clamp-4',
                5: 'line-clamp-5',
                6: 'line-clamp-6',
                none: 'line-clamp-none',
            },
            lineHeight: {
                // these are all relative line-heights; relative to the current font-size
                1: 'leading-none',
                1.25: 'leading-tight',
                1.375: 'leading-snug',
                1.5: 'leading-normal',
                1.625: 'leading-relaxed',
                2: 'leading-loose',
            },
            fontWeight: {
                regular: 'font-normal',
                medium: 'font-medium',
                semibold: 'font-semibold',
                bold: 'font-bold',
            },
            textAlign: {
                left: 'text-left',
                center: 'text-center',
                right: 'text-right',
                justify: 'text-justify',
                start: 'text-start',
                end: 'text-end',
            },
            textDecoration: {
                underline: 'underline',
                overline: 'overline',
                lineThrough: 'line-through',
                none: 'no-underline',
            },
            textTransform: {
                capitalize: 'capitalize',
                uppercase: 'uppercase',
                lowercase: 'lowercase',
                none: 'normal-case',
            },
            fontStyle: {
                italic: 'italic',
                normal: 'non-italic',
            },
            wordBreak: {
                all: 'break-all',
                words: 'break-words',
                keep: 'break-keep',
                normal: 'break-normal',
            },
            truncate: { true: 'text-ellipsis overflow-hidden whitespace-nowrap' },
            fontSize: {
                inherit: 'inherit',
                xxs: 'text-xxs',
                xs: 'text-xs',
                sm: 'text-sm',
                md: 'text-md',
                lg: 'text-lg',
                xl: 'text-xl',
                '2xl': 'text-2xl',
                '3xl': 'text-3xl',
                '4xl': 'text-4xl',
                '5xl': 'text-5xl',
                'display-xs': 'text-display-xs',
                'display-sm': 'text-display-sm',
                'display-md': 'text-display-md',
                'display-lg': 'text-display-lg',
                'display-xl': 'text-display-xl',
            },
        },
    },
    { responsiveVariants: true },
);

const typography = tvTypography.variants;

export { typography };
